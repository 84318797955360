// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-owner-js": () => import("./../src/components/owner.js" /* webpackChunkName: "component---src-components-owner-js" */),
  "component---src-components-season-js": () => import("./../src/components/season.js" /* webpackChunkName: "component---src-components-season-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-charter-mdx": () => import("./../src/pages/charter.mdx" /* webpackChunkName: "component---src-pages-charter-mdx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-owners-js": () => import("./../src/pages/owners.js" /* webpackChunkName: "component---src-pages-owners-js" */),
  "component---src-pages-records-js": () => import("./../src/pages/records.js" /* webpackChunkName: "component---src-pages-records-js" */),
  "component---src-pages-seasons-js": () => import("./../src/pages/seasons.js" /* webpackChunkName: "component---src-pages-seasons-js" */)
}

